<template>
  <div class="outermost">
    <layout
      class="layout"
      :hd="true"
      :ft="false"
    >
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div
        slot="le"
        class="hd-left"
        @click="returnMe"
      >
        <div></div>
        <span>智能客服</span>
      </div>
      <div class="container">
        <div class="newsList">

          <div
            class="item"
            v-for="(item,index) in messageList"
            :class="{service:item.type===1}"
            :key="index"
          >
            <div class="contentV">{{item.message}}</div>
            <div class="profilePhoto">
              <img
                v-if="item.type === 1"
                src="../../assets/img/me/service.png"
                alt=""
              >
              <img
                v-if="item.type === 2"
                :src="avatar"
                alt=""
              >
            </div>
          </div>
        </div>
        <div class="bottom">
          <van-field v-model="message" />
          <div
            class="send"
            @click="handleSend"
          >发送</div>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'
import { serviceMessage } from '@/api/me'
export default {
  components: {
    Layout
  },
  data () {
    return {
      message: '',
      avatar: '',
      messageList: []
    }
  },
  methods: {
    returnMe () {
      this.$router.go(-1)
    },
    handleSend () {
      let serviceObj = {
        type: 1,
        message: '暂无相关解答内容'
      }
      let userObj = {
        type: 2,
        message: this.message
      }
      if (this.message === '请问怎么军人认证') {
        serviceObj.message = '点击个人中心进去军人认证页面输入身份证认证。'
      } else if (this.message === '报名的活动怎么参加') {
        serviceObj.message = '报名后会提供一张二维码，到活动地点扫码。'
      } else if (this.message === '长辈模式怎么开启') {
        serviceObj.message = '点击个人中心把长辈默认按钮勾选即可。'
      }
      this.messageList.push(...[userObj, serviceObj])
      this.message = ''
    }
  },
  mounted () {
    this.avatar = this.$store.state.user.userInfo.avatar
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #fff;
  height: 100vh;
  .layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .layout /deep/ .main {
    background: #f2f3f5;
    padding-bottom: 0;
    padding-top: 80px;
    flex: 1;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
      color: #ffffff;
    }
  }
  .news-boder {
    position: relative;
    padding: 15px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 4px;
    .underline {
      margin-top: 13px;
      width: 325px;
      height: 1px;
      background: #dee0e8;
      border-radius: 4px;
    }
    .news-boder-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #12151b;
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .news-boder-title-left {
        display: flex;
        align-items: flex-start;
        flex: 1;
        div {
          margin-right: 5px;
        }
      }
      .news-boder-title-time {
        font-size: 12px;
        color: #868a93;
      }
    }
    .news-boder-content {
      margin-top: 11px;
      font-size: 14px;
      color: #464a55;
      word-break: break-all;
    }
  }
  .dot {
    position: absolute;
    top: 30px;
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 10px;
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .newsList {
      flex: 1;
      overflow-y: auto;
      padding-bottom: 50px;
      .service {
        justify-content: flex-start !important;
        padding-right: 0;
        padding-left: 20px;
        .profilePhoto {
          order: 0;
          margin-left: 0 !important;
          margin-right: 10px;
        }
        .contentV {
          order: 1;
        }
      }
      .item {
        height: 40px;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        margin-bottom: 20px;
        .contentV {
          max-width: 100%;
          height: 100%;
          line-height: 40px;
          padding: 0 10px;
          font-size: 14px;
          color: rgb(255, 255, 255);
          display: inline-block;
          background-color: #10955b;
          border-radius: 3px;
        }
        .profilePhoto {
          margin-left: 10px;
          width: 40px;
          height: 40px;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding-right: 10px;
      .send {
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #10955b;
        color: rgb(255, 255, 255);
        font-size: 14px;
        border-radius: 3px;
      }
    }
  }
}
.van-cell {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  background-color: #f6f6f6;
}
</style>